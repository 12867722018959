import "./styles.css";
import zodiacsMainInfo from "../../data/zodiacsSignsInfo";
import zodiacs from "../../data/zodiacs";
import { ReactComponent as ArrowIcon} from "../../assets/icons/arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Tootltip from "../Tooltip";

const Header = () => {
    const navigate = useNavigate();
    const { zodiac } = useParams();
    const [isHeaderHiden, setIsHeaderHiden] = useState(false);

    const redirectTo = (zodiac) => {
        navigate(`/${zodiac}`, { replace: true });
    }

    const isActive = (linkToSign) => {
        return zodiac === linkToSign;
    }

    const isDesktop = window.innerWidth > 950;

    return (
        <>
            <button className="hiding-button-container">
                <div 
                    className={`hiding-button ${isHeaderHiden && 'arrow-to-right'}`}
                    onClick={()=>setIsHeaderHiden(!isHeaderHiden)}
                >
                    <ArrowIcon />
                </div>
            </button>
            <header className={`header-сontainer ${isHeaderHiden && 'hiden'}`}>
                <nav className="zodiacs-container">
                    <ul>
                    {zodiacs.map(zodiacSign => {
                        return (
                            <li key={zodiacSign}>
                                <button className={`zodiac-link ${isActive(zodiacSign) && 'active'}`} onClick={()=>{redirectTo(zodiacSign)}}>
                                    {isDesktop ? 
                                        (<Tootltip tooltipInfo={zodiacsMainInfo[zodiacSign].label} children={<img src={zodiacsMainInfo[zodiacSign].imgSrc} alt={zodiacSign}/>}/>)
                                        : <p>{zodiacsMainInfo[zodiacSign].label}</p>
                                    }
                                </button>
                            </li>
                        )
                    })}
                    </ul>
                </nav>
            </header>
        </>
    )
    
}

export default Header;