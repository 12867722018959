import { useEffect } from 'react';
import './styles.css';

const AdBanner = () => {
    useEffect( () => {
        try {
            const script = document.createElement("script");
            script.src = "https://cdn1.moe.video/p/b.js";
            script.onload = () => {
                // eslint-disable-next-line
                addBanner({
                    element: '#banner',
                    placement: 11515,
                    width: '300px',
                    height: '600px',
                    advertCount: 0,
                    deviceMode: 'all',
                })
            }
            document.body.append(script);
        } catch (error) {
            console.log("Banner error");
        }
    }, [])

    return (
        <div id="banner"></div>
    )
}

export default AdBanner;